<template>

    <div class="row" ref="templateForm">
  
      <div class="col-xs-12 col-md-12 col-sm-12">
        <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">
  
          <card>
            <div slot="header">
              <h4 class="card-title">
                {{ formTitle }}
              </h4>
            </div>
  
            <div class="card-body">
              <!--                //Name & URL-->
              <div class="row">
                <div class="col-md-6">
                  <ValidationProvider
                    vid="status"
                    rules="required"
                    name="The Status"
                    v-slot="{ passed, failed,errors }">
                    <fg-select type="text"
                            :error="failed ? errors[0]: null"
                            label="Status"
                            name="status"
                            v-model="formData.status"
                            size="large"
                            filterable
                            placeholder="status"
                            :input-classes="'select-default'"
                            :list="inquiryStatuses"
                            :listItemLabel="'label'"
                            :listItemValue="'label'"
                            >
                    </fg-select>
                  </ValidationProvider>
                </div>
              </div>
            </div>
  
            <div class="card-footer text-right">
              <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                        nativeType="submit"
                        type="info" wide>Submit
              </l-button>
              <l-button @click="$router.push('/inquiries/list')" type="danger" wide>Cancel
              </l-button>
            </div>
          </card>
  
        </ValidationObserver>
  
      </div>
  
    </div>
  </template>
  <script>
  import {extend} from "vee-validate";
  import {Option, Select, Tooltip} from 'element-ui';
  import FgSelect from "@/components/Inputs/formGroupSelect";
  import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
  import Editor from '@tinymce/tinymce-vue'
  import {mapGetters} from "vuex";
  
  
  extend("required", {
    message: "{_field_} is required"
  });
  
  export default {
  
    components: {
      FormGroupTextArea,
      [Option.name]: Option,
      [Select.name]: Select,
      [Tooltip.name]: Tooltip,
      FgSelect,
      LSwitch,
      PrimeUploader,
      ImageUploader,
      PrimeVideoUploader,
      'editor': Editor
    },
  
    data() {
      return {
        editMode: false,
        loader: {},
        id: undefined,
        editorConfig: this.getEditorConfig(),
  
        formTitle: "",
        entityNotFoundError: false,
        submitting: false,
        editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
        inquiryStatuses : [],
        formData: {
          status: "",
        },
      };
    },
  
    computed: {
      ...mapGetters({
        mediaInfo: "global/mediaInfo"
      })
    },
    mounted() {
        this.loader = this.$loading.show({
            container: this.$refs.templateForm
        });
  
        this.id = this.$route.params['id'];
        this.editMode = true;
        this.formTitle = "Edit Inquiry Status";
        this.inquiryStatuses = [{'label' : 'Pending'}, {'label': 'Open'}, {'label' : 'Close'}, {'label' : 'New'}];
        this.getItem();
    },
  
  
    methods: {
      getItem() {
        let data = {
          'id': this.id,
        };
        this.axios.post("contact-us/get", data).then((response) => {
          this.formData = response.data;
          this.loader.hide();
        }).catch((error) => {
          if (error.response.status === 404) {
            this.entityNotFoundError = true;
            this.$notify({
              message: "Inquiry Not Found",
              timeout: 2000,
              type: 'danger'
            });
            this.loader.hide();
  
          } else {
            console.error(error);
          }
        })
      },
  
      submit() {
        let request;
        let successMessage;
        this.submitting = true;
        request = this.axios.put("contact-us/status-update/" + this.id, this.formData);
        successMessage = "Inquiry Updated Successfully";
        request.then((response) => {
          this.$notify({
            message: successMessage,
            timeout: 1000,
            type: 'success'
          })
          this.$router.push("/contact-us/list");
        }).catch((error) => {
          if (error.response.status === 422) {
            this.$refs.formValidator.setErrors(error.response.data.errors);
          } else {
            console.log(error.response)
          }
        }).finally(() => {
          this.submitting = false;
        })
      },
  
    }
  }
  </script>
  