<template>
  <div class="row">

    <div class="col-12">

      <card>

        <div slot="header" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <h4 class="card-title">Contact Messages List</h4>
          <div class="d-flex justify-content-start">
            <div class="text-center pt-1 px-1">
              <a v-if="$store.getters['auth/checkAccess']('contact-us/export')"
                 class="btn btn-info btn-wd export_excel" style="display:flex;"
                 @click="openModal('basic')"> Export to excel
                <span class="btn-label px-2">
                  <i class="nc-icon nc-cloud-download-93" style="font-size:20px;"></i>
                  </span>
              </a>
            </div>
          </div>
        </div>

        <!--    filters    -->
        <div class="row col-12 mb-2">
          <div class="col-md-4 col-sm-4">
            <fg-select
              size="large"
              filterable
              clearable
              placeholder="Select Sender Type"
              :input-classes="'select-default'"
              :list="filterBuilders.senderTypes"
              :listItemLabel="'label'"
              :listItemValue="'value'"
              v-model="filters.sender_type">
            </fg-select>
          </div>

          <div class="col-md-2 col-sm-4">
            <l-button class="mr-lg-1" @click="applyFilter()" type="info">Filter Result</l-button>
          </div>
        </div>
        <!--  end  filters    -->

        <div>
          <general-data-table
            ref="table"
            :advanceSearchFilter="advanceSearchFilter"
            :method="'POST'"
            :api-url="'contact-us/index'">
            <el-table-column v-for="column in tableColumns"
                             :key="column.label"
                             :min-width="column.minWidth"
                             :align="column.align"
                             :sortable="column.sortable"
                             :prop="column.value"
                             :label="column.label">

            </el-table-column>

            <el-table-column
              :min-width="120"
              :fixed="fixedColumn"
              align="center"
              label="Actions">
              <template slot-scope="scope">
                <router-link   v-if="$store.getters['auth/checkAccess']('contact-us/get')"
                              v-tooltip.top-center="'Show'"
                              class="btn-warning btn-simple btn-link"
                              :to="'/contact-us/show/'+scope.row.id">
                  <i class="fa fa-eye"></i>
                </router-link>

                <router-link   v-if="$store.getters['auth/checkAccess']('contact-us/update-status')"
                              v-tooltip.top-center="'Edit Status'"
                              class="btn-warning btn-simple btn-link"
                              :to="'/contact-us/update-status/'+scope.row.id">
                  <i class="fa fa-edit"></i>
                </router-link>

                <a v-if="$store.getters['auth/checkAccess']('contact-us/delete')" v-tooltip.top-center="'Delete'"
                   class="btn-danger btn-simple btn-link"
                   @click="openDeleteModal(scope.row.id)"><i class="fa fa-times"></i></a>
              </template>
            </el-table-column>
          </general-data-table>

        </div>


      </card>

      <delete-modal :visible="deleteModalVisibility"
                    :message="'Are you sure you want to delete this Message?'"
                    @close="closeDeleteModal()"
                    @confirm="confirmDeleteModal()">
      </delete-modal>
      <export-modal id="export-modal" title="Contact US" url="contact-us/export"
              :filters="exportFilters" :modals="modals"></export-modal>


    </div>

  </div>
</template>
<script>
import {TableColumn} from 'element-ui'
import {NewPagination as NLPagination, Switch as LSwitch} from '@/components'
import {DeleteModal as DeleteModal} from '@/components'
import GeneralDataTable from "@/components/GeneralDataTable";
import ExportModal from '@/components/ExportModal/ExportModal.vue';
import FgSelect from "../../../components/Inputs/formGroupSelect";


export default {
  components: {
    FgSelect,
    NLPagination,
    DeleteModal,
    LSwitch,
    GeneralDataTable,
    [TableColumn.name]: TableColumn,
    ExportModal
  },

  data() {

    return {
      tableColumns: [
        {label: 'First Name', value: 'first_name', minWidth: '200', align: 'center'},
        {label: 'Last Name', value: 'last_name', minWidth: '200', align: 'center'},
        {label: 'Email', value: 'email', minWidth: '200', align: 'center'},
        {label: 'Status', value: 'status', minWidth: '200', align: 'center'},
        {label: 'Sender', value: 'sender_type', minWidth: '200', align: 'center'},
        {label: 'Date', value: 'created_at', minWidth: '200', align: 'center'},
      ],
      fixedColumn: null,
      modals: {
        basic: false,
        centered: false,
        custom: false,
        confirm: false
      },
      advanceSearchFilter: [
        {
          key: 'contactus.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: 'from',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'contactus.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: 'to',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'contactus.full_name',
          type: 'text',
          frontType:'simple',
          label: 'Name',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'contactus.email',
          type: 'text',
          frontType:'simple',
          label: 'Email',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'contactus.subject',
          type: 'text',
          frontType:'simple',
          label: 'Subject',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'contactus.company',
          type: 'text',
          frontType:'simple',
          label: 'Company',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'contactus.message',
          type: 'text',
          frontType:'simple',
          label: 'Message',
          class: 'col-md-12 col-xs-12',
          value: null,
        },
      ],
      exportFilters: [
        {
          key: 'contactus.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'gt',
          label: 'from',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'contactus.created_at',
          type: 'date',
          frontType:'simple',
          strategy: 'lt',
          label: 'to',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'contactus.full_name',
          type: 'text',
          frontType:'simple',
          label: 'Name',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'contactus.email',
          type: 'text',
          frontType:'simple',
          label: 'Email',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'contactus.subject',
          type: 'text',
          frontType:'simple',
          label: 'Subject',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'contactus.company',
          type: 'text',
          frontType:'simple',
          label: 'Company',
          class: 'col-md-6 col-xs-12',
          value: null,
        },
        {
          key: 'contactus.message',
          type: 'text',
          frontType:'simple',
          label: 'Message',
          class: 'col-md-12 col-xs-12',
          value: null,
        },
      ],

      deleteModalVisibility: false,
      toDeleteId: undefined,
      url : process.env.VUE_APP_SERVER_IMAGE_URL,

      filterBuilders: {
        senderTypes: [
          {label: 'Parent', value: 'parent'},
          {label: 'School', value: 'school'},
        ]
      },

      filters: {
        sender_type: null,
      },
    }

  },
  created() {
      this.responsiveViewPort();
  },
  methods: {

    openDeleteModal(id) {
      this.deleteModalVisibility = true;
      this.toDeleteId = id;
    },

    closeDeleteModal() {
      this.deleteModalVisibility = false;
    },
    confirmDeleteModal() {

      let data = {
          'id': this.toDeleteId,
      }
      this.axios.delete("contact-us/delete", {headers: {}, data}).then((response) => {

        this.$notify({
          message: "Message deleted successfully",
          timeout: 1000,
          type: 'success'
        });

        this.$refs.table.getData("updateData");

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          timeout: 2000,
          type: 'danger'
        })
      })

      this.closeDeleteModal();
      this.toDeleteId = undefined;
    },
    openModal (name) {
      this.modals[name] = true
    },
    closeModal (name) {
      this.modals[name] = false
    },
    responsiveViewPort(){
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            this.fixedColumn = null;
        }else{
            this.fixedColumn = 'right';
        }
    },
    applyFilter() {
      this.$refs.table.filters = {...this.filters};
      if (this.filters.sender_type) {
        this.$refs.table.createDragAndDropSorting();
      } else {
        this.$refs.table.destroyDragAndDropSorting();
      }
      this.$refs.table.getData("updateData");
    },
  },
}
</script>

